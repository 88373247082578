<template>
  <div :class="contentWidth === 'boxed' ? 'container p-0' : null">
    <b-alert
      v-height-fade.appear
      :show="internetFailed"
      class="internetAlert text-center"
      variant="danger"
    >
      <div class="alert-body">
        {{ $t("Conexão perdida!") }}
      </div>
    </b-alert>
    <router-view />
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import { BAlert } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";

export default {
  data() {
    return {
      internetFailed: false,
    };
  },
  components: {
    BAlert,
  },
    directives: {
    "height-fade": heightFade,
    Ripple,
  },
  mounted() {
    this.verifyInternet();
  },
  methods: {
    verifyInternet() {
      setInterval(() => {
        if (navigator.onLine) {
          this.internetFailed = false;
        } else {
          this.internetFailed = true;
          this.loading = false;
        }
      }, 3000);
    },
  },
  setup() {
    const { contentWidth } = useAppConfig();
    return { contentWidth };
  },
};
</script>